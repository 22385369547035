import React from 'react'
import Figure from './Figure'
import { Link } from 'gatsby'
import SyntaxHighlighter from 'react-syntax-highlighter'
import docco from 'react-syntax-highlighter/dist/esm/styles/hljs/docco'

const serializers = {
  types: {
    // authorReference:  ({node}) => <span>{node.author.name}</span>,
    code: ({ node = {} }) => {
      const { code, language } = node
      if (!code) {
        return null
      }
      return (
        <SyntaxHighlighter language={language || 'text'} style={docco}>
          {code}
        </SyntaxHighlighter>
      )
    },
    // code: props => <pre>{JSON.stringify}</pre>
    mainImage: Figure
  },
  marks: {
    link: (props) => {
      console.log(props)
      return (
        <a href={props.mark.href} className='text-red-500' target='_blank'>{props.children}</a>

      )
    },
    internalLink: (props) => {
      // console.log(props)
      return (
        <Link to={props.mark.slug}>
          {props.children}
        </Link>
      )
    }

  }
}

export default serializers
