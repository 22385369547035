import React from 'react'

const Container = ({ children }) => {
  return (
    <div className='lg:pl-32 mt-0'>
      <div className='max-w-xl'>{children}</div>
    </div>
  )
}

export default Container
