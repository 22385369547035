// import } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/layout'

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }
  return (
    <div>
      <Layout
        {...props}
        showNav={showNav}
        onHideNav={handleHideNav}
        onShowNav={handleShowNav}
      />
    </div>
  )
}

export default LayoutContainer
